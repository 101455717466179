//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CurveShape",
};
