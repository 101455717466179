//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import $ from "jquery";
import CurveShape from "@/modules/layout/components/curve-shape.vue";

import { scroll } from "quasar";
const { getScrollTarget, setScrollPosition } = scroll;
import i18n from "@/vueI18n";
export default {
  name: "PageTitle",
  components: {
    CurveShape,
  },
  computed: {
    filled() {
      return this.i18n("homeHeader.title");
    },
    language() {
      return i18n.locale;
    },
  },
  watch: {
    language() {
      var x = document.getElementById("typed");
      console.log(x);
    },
  },
  methods: {
    handleScroll(eleId) {
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      // const header = document.getElementById("header");
      const offset = ele.offsetTop - 250;
      const duration = 500;
      setScrollPosition(target, offset, duration);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
  mounted() {},
};
