//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import CurveShape from "@/modules/layout/components/curve-shape.vue";

export default {
  name: "About-us",
  // components: {
  //     CurveShape,
  // },
  computed: {},
};
