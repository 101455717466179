//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContactsForm from "@/modules/home/components/contactsForm.vue";
import i18n from "@/vueI18n";

export default {
  name: "Conacts",
  components: {
    ContactsForm,
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
};
