//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductData from "@/shared/json/product-data.json";
import ProductDataAr from "@/shared/json/product-data-ar.json";
import productCard from "./product-card.vue";
import i18n from "@/vueI18n";
export default {
  name: "Products",
  components: {
    productCard,
  },

  computed: {
    language() {
      return i18n.locale;
    },
    products() {
      if (this.language == "en") return ProductData.products;
      return ProductDataAr.products;
    },
    spareParts() {
      if (this.language == "en") return ProductData.spareParts;
      return ProductDataAr.spareParts;
    },
  },
  methods: {
    goToProducts() {
      this.$router.push(`/${this.language}/products`);
    },
    goToSpareParts() {
      this.$router.push(`/${this.language}/spare-parts`);
    },
    viewProduct(item) {
      this.$router.push(`/${this.language}/products/${item.id}`);
    },
    viewSparePart(item) {
      this.$router.push(`/${this.language}/spare-parts/${item.id}`);
    },
  },
};
